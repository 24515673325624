<template>
  <v-app>
    <v-navigation-drawer v-model="$store.state.showDrawer" app :mobile-breakpoint="0" style="z-index:9;" temporary right>
      <div style="text-align:center;font-size:30px;margin-top:10%;margin-bottom:5%;position:relative;">
        <v-icon style="position:absolute;right:15px;top:-10px;cursor:pointer;" @click="closeDrawer">mdi-close</v-icon>
        <img src="@/assets/logo.png" alt width="60px" @click="toTop" style="cursor:pointer;z-index:1;" />

        <div style="z-index:0;height:20px;font-size:12px;font-weight:300;margin-top:-10px;">
          <span @click="toTop" style="cursor:pointer;">EppBuyer</span>
        </div>
      </div>
      <v-list dense>
        <v-list-item link index="2" @click="toDownload">
          <v-list-item-action>
            <v-icon>mdi-download-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title style="font-size:14px;font-family:Noto Sans TC;font-weight:300;">下載</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link index="2" @click="toBuy">
          <v-list-item-action>
            <v-icon>mdi-cart-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title style="font-size:14px;font-family:Noto Sans TC;font-weight:300;">價格</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link index="88" @click="toAdmin">
          <v-list-item-action>
            <v-icon>mdi-login-variant</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title style="font-size:14px;font-family:Noto Sans TC;font-weight:300;">後台管理</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app :class="showAppBarBgColor ? getBgColorClass : getBgColorClass + ' my-transparent'" :elevation="showAppBarBgColor ? 0 : 0">
      <v-toolbar-title>
        <img
          :src="toolbarMainColor == 'white' ? require('@/assets/logo_with_text_white.png') : require('@/assets/logo_with_text_black.png')"
          alt=""
          :width="getTitleLogoWidth + 'px'"
          style="margin-top:8px;cursor:pointer;"
          @click="toTop"
        />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        :dark="toolbarMainColor == 'white' ? true : false"
        rounded
        text
        style="margin:0 5px;"
        @click="toDownload"
        v-if="$store.state.screenWidth >= 583"
      >
        下載
      </v-btn>
      <v-btn
        :dark="toolbarMainColor == 'white' ? true : false"
        rounded
        text
        style="margin:0 5px;"
        @click="toBuy"
        v-if="$store.state.screenWidth >= 583"
      >
        價格
      </v-btn>
      <v-btn
        rounded
        dark
        @click="toAdmin"
        v-if="$store.state.screenWidth >= 583"
        small
        color="#0D47A1"
        style="margin:0 5px;font-size:14px;background: linear-gradient(180deg, rgba(47,90,205,1) 0%, rgba(6,44,147,1) 100%);"
      >
        後台管理
      </v-btn>
      <v-app-bar-nav-icon
        v-if="$store.state.screenWidth < 583"
        @click.stop="$store.state.showDrawer = !$store.state.showDrawer"
        :style="{ color: toolbarMainColor }"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <v-main :style="{ 'padding-top': 0, 'background-image': 'url(' + require('@/assets/111111.jpg') + ')' }">
      <router-view :themeColor="toolbarMainColor == 'black' ? 0 : 1"></router-view>
    </v-main>
    <SnackBar :showObj="$store.state.msg"></SnackBar>
  </v-app>
</template>

<script>
import SnackBar from "@/components/SnackBar.vue";
export default {
  data() {
    return {
      toolbarMainColor: "white",
      showAppBarBgColor: false
    };
  },
  components: {
    SnackBar
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.$store.commit("setScreenWidth", document.body.clientWidth);
        this.$store.commit("setScreenHeight", window.screen.height);
      })();
    };
    this.$store.commit("setScreenWidth", document.body.clientWidth);
    this.$store.commit("setScreenHeight", window.screen.height);

    this.$nextTick(function() {
      //监听滚动事件
      window.addEventListener("scroll", this.scrollHandler);
    });

    if (this.$route.hash == "#buy") {
      setTimeout(() => {
        this.toBuy();
      }, 1000);
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollHandler);
  },
  computed: {
    getTitleLogoWidth() {
      return parseInt(this.$store.state.screenWidth / 10) > 110 ? parseInt(this.$store.state.screenWidth / 10) : 110;
    },
    getBgColorClass() {
      return this.toolbarMainColor == "black" ? "custom-app-bar-light" : "custom-app-bar-dark";
    }
  },
  methods: {
    toTop() {
      this.$store.commit("setShowDrawer", false);
      let scrollToTop = window.setInterval(() => {
        let Top = document.documentElement.scrollTop || document.body.scrollTop;
        if (Top > 0) {
          window.scrollTo(0, Top - 64);
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 10);
      this.leaveFocus();
    },
    toDownload() {
      this.$store.commit("setShowDrawer", false);
      let offsetTop = document.getElementById("DownloadContainer").offsetTop; //目標元素上緣離頂部距離
      window.scrollTo({ top: offsetTop - 64, behavior: "smooth" });
      this.leaveFocus();
    },
    toBuy() {
      this.$store.commit("setShowDrawer", false);
      let offsetTop = document.getElementById("priceMenuContainer").offsetTop; //目標元素上緣離頂部距離
      window.scrollTo({ top: offsetTop - 64, behavior: "smooth" });
      this.leaveFocus();
    },
    toAdmin() {
      this.$store.commit("setShowDrawer", false);
      setTimeout(() => {
        window.open("https://admin.eppbuyer.com", "_blank");
      }, 100);
    },
    leaveFocus() {
      if (document.activeElement != document.body) {
        document.activeElement.blur();
      }
    },
    closeDrawer() {
      this.$store.commit("setShowDrawer", false);
    },
    scrollHandler() {
      if (window.pageYOffset > this.$store.state.screenWidth / 6) {
        this.showAppBarBgColor = true;
      } else {
        this.showAppBarBgColor = false;
      }
    }
  }
};
</script>

<style scoped>
.custom-app-bar-light {
  background-color: #efefef !important;
  transition: background-color 0.5s;
}

.custom-app-bar-dark {
  background-color: #2e95e4 !important;
  transition: background-color 0.5s;
}

.my-transparent {
  background-color: transparent !important;
  transition: background-color 1s;
}
</style>
